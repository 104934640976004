import React from "react";
// import ReactPlayer from "react-player";
import JoinAdd from "../JoinAdd/JoinAdd";
import ReactPlayerComp from "../ReactPlayerComp/ReactPlayerComp";
import "./RedBanner.css";
import { v4 as uuidv4 } from "uuid";
export default function RedBanner({ modalHandler }) {
	const handleWhatsapp = () => {
		window.open("https://wa.me/971568819394?text=Hi%20", "_blank");
	};
	return (
		<div className='banner text-center'>
			<div className='landing'>
				<div className='title d-flex align-items-center justify-content-center'>
					<img src='assets/RED_Logo.svg' alt='' />
				</div>
				<div className='tagline '>
					Reduce <span className='gradient-text'>Fat.</span> Reduce{" "}
					<span className='gradient-text'>Inches.</span> Reduce{" "}
					<span className='gradient-text'>Stress</span> - The Yogic
					Way!
				</div>
				<div className='description'>
				Developed by the living Himalayan master Dr Bharat Thakur, Reduce Every Day (RED) program combines the best of modern workout techniques and powerful yogic practices to increase your metabolism working on the deepest layers of muscles. The result? A thinner, fitter, calmer and more energetic you!
				</div>
			</div>
			<div className='featureBrief row row-cols-1 row-cols-xs-2  row-cols-md-1 row-cols-lg-2'>
				<div className='pt-4 pb-4 pb-sm-4 pb-md-0 d-flex flex-column justify-content-end px-3 px-sm-5 align-items-center'>
					<div className='row row-cols-2 gy-4  mb-2 bannerIcons' >
						<div className='d-flex flex-column justify-content-center align-items-center'>
							<img
								width="48px"
								src='assets/calander.svg'
								className='img-fluid d-none d-sm-inline-block'
								alt=''
							/>
							<img
								width="32px"
								src='assets/calanderMobile.svg'
								className='img-fluid d-sm-none d-inline-block'
								alt=''
							/>
							<span className='briefIdea'>5 Days a Week</span>
						</div>
						<div className='d-flex flex-column justify-content-center align-items-center'>
							<img
								width="48px"
								src='assets/video.svg'
								className='img-fluid d-none d-sm-inline-block'
								alt=''
							/>
							<img
								width="32px"
								src='assets/videoMobile.svg'
								className='img-fluid d-sm-none d-inline-block'
								alt=''
							/>
							<span className='briefIdea'>Live Zoom Class</span>
						</div>
						<div className='d-flex flex-column justify-content-center align-items-center BannnerSectionTimer' >
							<img
								width="48px"
								src='assets/timer.svg'
								className='img-fluid  d-none d-sm-inline-block'
								alt=''
							/>
							<img
								width="32px"
								src='assets/timerMobile.svg'
								className='img-fluid  d-sm-none d-inline-block'
								alt=''
							/>
							<span className='briefIdea'>1 Hour Daily</span>
						</div>
						<div className='d-flex flex-column justify-content-center align-items-center BannnerSectionMeditation'>
							<img
								width="48px"
								src='assets/meditation.svg'
								className='img-fluid d-none d-sm-inline-block'
								alt=''
							/>
							<img
								width="32px"
								src='assets/meditationMobile.svg'
								className='img-fluid d-sm-none d-inline-block'
								alt=''
							/>
							<span className='briefIdea'>Ongoing Classes</span>
						</div>
					</div>
					<div className='mt-4 px-4 px-sm-0 position-relative'>
						<JoinAdd modalHandler={modalHandler} />
						<div onClick={handleWhatsapp} className="ChatOptionMobile d-flex d-sm-flex d-md-none">
						<img width={22} height={20} src="assets/WhatsappGreen.svg" alt=""/><p className="ms-1">Chat with us</p> 
						</div>
					</div>

					{/* <div className='joinAd my-4'>
						<span>
							Join Today for just{" "}
							<span className='strikethrough'>350</span>{" "}
							<span style={{ fontSize: "28px" }}>200 </span> AED
						</span>
					</div> */}
				</div>
				<p className="mb-3 sm-mb-3 d-block d-sm-block d-md-none FounderMessage">A Message from Our Founder</p>
				<div className='bannerVideo mt-0 mt-sm-0 mt-md-5 mt-lg-0'>
					<ReactPlayerComp
						url={
							"https://artisticyoga.com/uploads/videos/red/testimonial/BT-RED-VIDEO.mp4?ngsw-bypass=true"
						}
						thumb='assets/thumbnail/Thumb/BTREDVideo.jpg'
						uid={uuidv4()}
					/>
				</div>
			</div>
			<div className='stats py-4'>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/heart.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/heartMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>1,672,000</div>
					<div className='brief'>Lives Transformed</div>
				</div>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/meter.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/meterMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>6,688,000</div>
					<div className='brief'>Kgs Lost</div>
				</div>
				<div className='stateBox'>
					<div className='logo'>
						<img
							src='assets/clock.svg'
							alt=''
							className='img-fluid d-none d-sm-inline-block'
						/>
						<img
							src='assets/timerMobile.svg'
							alt=''
							className='img-fluid d-sm-none d-inline-block'
						/>
					</div>
					<div className='number'>13,260,000</div>
					<div className='brief'>Hours of Yoga</div>
				</div>
			</div>
		</div>
	);
}
